import React from 'react'

const Newsletter = () => {
  return (
    <div className="newsletter">
      <form className="newsletter-form">
        <input type="email" className="input-newsletter" placeholder="Enter your email here" />
        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default Newsletter
