import React, { FC } from "react";
import { Link } from "gatsby";
import { MapPin, Mail, PhoneCall } from "react-feather";

import Newsletter from "components/layout_v2/Newsletter";
import { ContactType } from "models/graphql/contact";

const logo = require("assets/images/new/logo.png");

const Footer: FC<{ contact?: ContactType }> = ({ contact = {} }) => {
  const {
    address,
    email,
    phone,
    phone2,
    linkedin,
    twitter,
    facebook,
    instagram,
  } = contact as ContactType;

  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img alt="logo" src={logo} />
                </Link>
              </div>

              <ul className="footer-contact-info">
                {!!address && (
                  <li>
                    <MapPin />
                    816 Ahmadu Bello Way, Central Business Dis 900103, Abuja, Federal Capital
                    Territory
                  </li>
                )}

                {!!email && (
                  <li>
                    <Mail />
                    <a href={`mailto:${email}`}>info@projectlighthouse.gov.ng</a>
                  </li>
                )}

                {(!!phone || !!phone2) && (
                  <li>
                    <PhoneCall />
                    Phone: {!!phone && <a href={`tel:${phone}`}>08188879988</a>}{" "}
                    {!!phone2 && <a href={`tel:${phone2}`}>{phone2}</a>}
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>About Us</h3>
              <ul className="list">
                <li>
                  <Link to="/news">News Update</Link>
                </li>
                <li>
                  <Link to="/reports">Docs & reports</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <ul className="list">
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Newsletter</h3>

              <div>
                <Newsletter />
              </div>

              <ul className="social-links">
                {!!twitter && (
                  <li>
                    <a
                      className="twitter"
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`https://twitter.com/${twitter}`}
                    >
                      <svg
                        stroke="none"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
                      </svg>
                    </a>
                  </li>
                )}

                {!!facebook && (
                  <li>
                    <a
                      className="facebook"
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`https://facebook.com/${facebook}`}
                    >
                      <svg
                        stroke="none"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
                      </svg>
                    </a>
                  </li>
                )}

                {!!instagram && (
                  <li>
                    <a
                      className="instagram"
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`https://instagram.com/${instagram}/`}
                    >
                      <svg
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zM17.5 6.5h.01" />
                      </svg>
                    </a>
                  </li>
                )}

                {!!linkedin && (
                  <li>
                    <a
                      className="facebook"
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`https://linkedin.com/${linkedin}/`}
                    >
                      <svg
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
                        <circle cx="4" cy="4" r="2" />
                      </svg>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>&copy;Lighthouse {currentYear}. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
