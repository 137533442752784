import React, { FC } from 'react'

interface Props {
  backgroundImage?: string
  title?: string
}

const Banner: FC<Props> = ({ backgroundImage = '', title }) => (
  <div
    className="page-title-area"
    style={{
      backgroundImage: `url(${backgroundImage})`,
    }}
  >
    <div className="title-gradient" />
    <div className="d-table">
      <div className="d-table-cell">
        <div className="container">{!!title && <h2>{title}</h2>}</div>
      </div>
    </div>
  </div>
)

export default Banner
