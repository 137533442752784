import { makeAutoObservable, observable, flow, action } from "mobx";
import { System_UserType } from "models/db-schema";
import JqlService from "common/client";
import { AUTHENTICATION_CLASS } from "common/constants";

class AuthenticationStore {
  isLoggedIn: boolean = false;
  isLoading: boolean = true;
  user: System_UserType | {} = {};

  /**
   * Begin this MobX observable class for reactivity across the app
   */
  // makeObservable(this, { value: observable, double: computed, increment: action, fetch: flow });
  constructor() {
    makeAutoObservable(this, {
      isLoggedIn: observable,
      isLoading: observable,
      user: observable,
      setLoading: action,
      setAuth: action,
      logout: action,
      verify: flow,
    });
  }

  /**
   * Login the user using the supplied credential
   * @param username
   * @param password
   */
  setLoading = (value: boolean) => {
    this.isLoading = value;
  };
  setAuth = ({ user, isLoggedIn }) => {
    this.user = user;
    this.isLoggedIn = isLoggedIn;
  };

  /**
   * Log this user out by called the server logout session to kill the httpOnly cookie
   */
  logout = async () => {
    try {
      await JqlService.post(JqlService.JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "system.User",
          schema: "controller",
          intent: "logout",
        },
      });
      this.user = {};
      this.isLoggedIn = false;
      localStorage.removeItem("token");
    } catch (error) {
      // throw error;
    }
  };

  /**
   * Verify the user credential against the server
   */
  verify = async () => {
    try {
      this.isLoading = true;
      const response = await JqlService.post(JqlService.JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "system.User",
          schema: "controller",
          intent: "verify_authentication",
          return: {
            email: null,
            first_name: null,
            last_name: null,
            user_type: null,
            phone: null,
            phone_country_code: null,
            id: null,
          },
        },
      });
      this.user = response?.data?.data || {};
      this.isLoggedIn = true;
    } catch (e) {
      await this.logout();
    } finally {
      this.isLoading = false;
    }
  };
}

export default new AuthenticationStore();
