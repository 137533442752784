declare var ENVIRONMENT: string;

let env;

if (typeof ENVIRONMENT === undefined) {
  // @ts-ignore
  env = process.env.ENVIRONMENT;
} else {
  env = ENVIRONMENT;
}

// console.log("ENV", env);

export const baseURL = {
  production: "https://api.projectlighthouse.gov.ng/jql",
  staging: "https://projectlighthouse-api.devnyn.com/jql",
  development: "http://projectlighthouse-api.devnyn.com/jql",
  local: "http://localhost:7050/jql",
};
export const authClasses = {
  production: "jwt",
  staging: "jwt",
  development: "jwt",
  local: "jwt",
};
export const authClass = authClasses[env];
export const JQL_ENDPOINT = baseURL[env];
export const APP_ERROR_404_URL = "/error/error-v1";
export const DEFAULT_URL = "/";

export const ETRANZACT_ENV = "testing";

export const ETRANZACT_URLS = {
  testing: {
    cardPaymentIntent: "https://demo.etranzact.com/CardProcessor/merchant-details/initiate",
    cardPaymentTransactionQuery:
      "https://demo.etranzact.com/CardProcessor/webconnect/api/v1/re-query",
  },
  production: {
    cardPaymentIntent: "https://demo.etranzact.com/CardProcessor/merchant-details/initiate",
    cardPaymentTransactionQuery:
      "https://demo.etranzact.com/CardProcessor/webconnect/api/v1/re-query",
  },
};
