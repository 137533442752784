import { AUTHENTICATION_CLASS } from "common/constants";
import axios, { AxiosRequestConfig } from "axios";
import { ErrorFactory, getCookie } from "common/utils";
import { JQL_ENDPOINT } from "common/config";
import { CastJqlType, JqlBaseResponse, JqlQueryType } from "models/types/Services";
import { EtranzactCardType } from "models/types/Generic";
import authenticationStore from "stores/AuthenticationStore";

declare module "axios" {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(
      url: string,
      data?: JqlQueryType<CastJqlType<T>>,
      config?: AxiosRequestConfig,
    ): Promise<JqlBaseResponse<T>>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    JQL_ENDPOINT: string;
  }
}

const client = axios.create({
  baseURL: JQL_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.JQL_ENDPOINT = JQL_ENDPOINT;

client.interceptors.request.use((config) => {
  if (config?.baseURL && config?.baseURL?.indexOf("/jql") > -1) {
    if (AUTHENTICATION_CLASS === "session") {
      config.headers["X-CSRFToken"] = getCookie("csrftoken");
      config.withCredentials = true;
    } else {
      config.headers["authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }
  } else {
    // console.log("without credentials", config?.baseURL);
  }

  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err?.response?.status === 401 ||
      (err?.response?.status === 400 && err?.response?.data?.errors === "token_not_valid")
    ) {
      authenticationStore.logout();
      return Promise.reject(err);
    }
    let errors = new ErrorFactory(err?.response).make().render();

    if (!errors) {
      errors = "An unknown error occurred " + err?.data;
    }
    return Promise.reject(err);
  },
);

export const JqlService = client;

export default client;

export const etranzactClient = ({ mode }: EtranzactCardType) =>
  axios.create({
    headers: {
      "Api-Key":
        "umWpFCOyVktReQ43Hg56TPiYaqT762qoCNsCQaICJkgXZkPeyz2EEMyLnpTfaNLYjF01UIUTReq45Re46",
      "Secret-Key": "DEMO_KEY",
      "Terminal-Id": "0000000001",
    },
  });
