import React, { FC } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'

interface Props {
  content?: { title: string; description: string }[]
}

const AccordionEl: FC<Props> = ({ content = [] }) => (
  <section className="faq-area ptb-80">
    <div className="container">
      <div className="faq-accordion">
        {!!content.length && (
          <Accordion>
            {content.map(({ title, description }, i) => (
              <AccordionItem key={i}>
                <AccordionItemHeading>
                  <AccordionItemButton>{title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{description}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  </section>
)

export default AccordionEl
