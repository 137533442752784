// Autogenerated model schema for Typescript

// ***** Model Schema for System

export type System_MDAType = {
  id: number;
  code?: string;
  name: string;
  alias?: string;
  abbr?: string;
  fqn: string;
};

export enum UserTypes {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  MDA_ADMIN = "MDA_ADMIN",
  MDA_USER = "MDA_USER",
  CORPORATE_REPRESENTATIVES = "CORPORATE_REPRESENTATIVES",
}

export type System_UserType = {
  id: number;
  firstName?: string;
  lastName?: string;
  fullname?: string;
  email?: string;
  username: string;
  phone?: string;
  mda?: System_MDAType;
  userType: UserTypes;
  fqn: string;
};

export type System_ReportBasketType = {
  id: number;
  fqn: string;
};

// ***** Model Schema for Profile

export type Profile_CompanyType = {
  id: number;
  uuid?: string;
  registrationNumber?: string;
  jtbTin?: string;
  tin?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  website?: string;
  directors?: None;
  businessName: boolean;
  verified: boolean;
  amountSummary?: string;
  numCreditors?: number;
  numDebts?: number;
  fqn: string;
};

export type Profile_PersonType = {
  id: number;
  user?: None;
  uuid?: string;
  tin?: string;
  jtbTin?: string;
  nin?: string;
  bvn?: string;
  passportNumber?: string;
  drivingLicense?: string;
  firstName?: string;
  lastName?: string;
  fullnameLegacy?: string;
  phone?: string;
  email?: string;
  verified: boolean;
  altPk: number;
  fqn: string;
};

export type Profile_PersonIdentityType = {
  id: number;
  fqn: string;
};

export type Profile_CompanyRepresentativeType = {
  id: number;
  company: Profile_CompanyType;
  user: System_UserType;
  admin: boolean;
  verified: boolean;
  comment?: string;
  fqn: string;
};

export type Profile_CompanyDocumentType = {
  id: number;
  company: Profile_CompanyType;
  name: "certificate_of_incorporation" | "cac_2" | "cac_7" | "director_consent";
  file?: string;
  comment?: string;
  status: "pending" | "approved" | "rejected";
  fqn: string;
};

// ***** Model Schema for Debt

export type Debt_DebtTypeType = {
  id: number;
  mda: System_MDAType;
  name: string;
  alias?: string;
  fqn: string;
};

export type Debt_DebtTypeCurrencySummaryType = {
  id: number;
  mda?: System_MDAType;
  debtType: Debt_DebtTypeType;
  amountOwed: number;
  amountOwedDeclared: number;
  variance: number;
  amountPaid: number;
  amountOutstanding: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  fqn: string;
};

export type Debt_DebtMDACurrencySummaryType = {
  id: number;
  mda: System_MDAType;
  amountOwed: number;
  amountOwedDeclared: number;
  variance: number;
  amountPaid: number;
  amountOutstanding: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  fqn: string;
};

export type Debt_DebtorCorporateType = {
  id: number;
  company?: Profile_CompanyType;
  mda: System_MDAType;
  amountOwed: number;
  amountOwedDeclared: number;
  variance: number;
  amountPaid: number;
  amountOutstanding: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  fqn: string;
};

export type Debt_DebtorPersonType = {
  id: number;
  person?: Profile_PersonType;
  mda: System_MDAType;
  amountOwed: number;
  amountOwedDeclared: number;
  variance: number;
  amountPaid: number;
  amountOutstanding: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  fqn: string;
};

export type Debt_DebtType = {
  id: number;
  debtType: Debt_DebtTypeType;
  company?: Profile_CompanyType;
  person?: Profile_PersonType;
  government?: System_MDAType;
  amountOwed: number;
  amountOwedDeclared: number;
  variance: number;
  amountPaid: number;
  amountOutstanding: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  fqn: string;
};

export type Debt_DebtLogType = {
  id: number;
  reference?: string;
  debt: Debt_DebtType;
  amount: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  date?: string;
  fqn: string;
};

export type Debt_PaymentTransactionLogType = {
  id: number;
  reference?: string;
  debt: Debt_DebtType;
  amount: number;
  currency: "ngn" | "usd" | "gbp" | "eur";
  logType: "waiver" | "payment" | "penalty" | "interest";
  paymentDate?: string;
  remarks?: string;
  status: string;
  completed: boolean;
  emailSent: boolean;
  fqn: string;
};

export type Debt_ManualTransactionLogType = {
  id: number;
  fqn: string;
};

export type Debt_DirectDebitTransactionLogType = {
  id: number;
  fqn: string;
};

export type Debt_CardPaymentTransactionLogType = {
  id: number;
  fqn: string;
};
