import React from "react";
import { System_UserType } from "models/db-schema";

type IAuthContext = {
  isLoggedIn?: boolean;
  logout: () => void;
  currentUser?: System_UserType | null;
};

const AuthContext = React.createContext<IAuthContext>({
  isLoggedIn: false,
  logout: () => {},
  currentUser: null,
});

export default AuthContext;
