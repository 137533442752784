import _ from "lodash";
import toast from "react-hot-toast";

export type ToastType = {
  message: string;
  type?: "error" | "notice" | "info" | "success";
  delay?: number;
};

export interface ErrorType {
  status_code: number;
  error_code: string;
  error_message: string;
}

export interface NoticeType {
  status_code: number;
  notice_code: string;
  notice_message: string;
}

export interface ResponseType {
  data: any;
}

/**
 * A class that handles popup notifications using PNotify library
 */
export class Toast {
  static makeToast({ message, type, delay = 3000 }: ToastType): void {
    delay = type === "error" ? 15000 : type === "notice" ? 15000 : 5000;

    if (message) {
      console.log("PNOTIFY MESSAGE", message);
      toastr[type || "notice"](message, { timeOut: delay });
    }
  }

  static destroyToasts(): void {}
}

/**
 * Handle errors sent back from the backend
 */
export class ErrorFactory {
  parsed = "";
  payload: ErrorType | ErrorType[];

  constructor(payload: ResponseType) {
    this.payload = payload?.data?.errors;
    console.log("ERROR PAYLOAD", this.payload);
  }

  make() {
    let index = 1;

    if (Array.isArray(this.payload)) {
      for (const x of this.payload) {
        this.parsed += `${index}. ${x.error_message}\n`;
        index++;
      }
    } else {
      _.map(this.payload, (v, k) => {
        this.parsed += `${index}. ${v.toString()} [${k}]\n`;
        index++;
      });
    }

    return this;
  }

  render() {
    return this.parsed;
  }
}

export class NoticeFactory {
  parsed: any = null;
  payload: NoticeType[];

  constructor(payload: NoticeType[]) {
    this.payload = payload;
  }

  make() {
    let index = 1;
    if (this.payload) {
      this.parsed = this.payload ? "" : null;

      for (const x of this.payload) {
        this.parsed += `${index}. ${x.notice_message}`;
        index++;
      }
    }
    return this;
  }

  render() {
    return this.parsed;
  }
}

export function getCookie(cname: string) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function parseUrlQuery(url: string = ""): Record<string, string> {
  const query = url.split("?")[1];
  if (!query) return {};

  return query.split("&").reduce((acc: Record<string, string>, param) => {
    const [k, v] = param.split("=");
    acc[k] = decodeURIComponent(v);
    return acc;
  }, {});
}

/**
 * This function wil recursively work through the label and keys and return an array format
 * of label and values
 * e.g. formatSelectOptions([...debts], "debt_type.mda.name", "debt_type.mda.id)
 * @param data
 * @param valueKey
 * @param labelKey
 */
export function formatSelectOptions(
  data: Array<Object>,
  valueKey: string,
  labelKey: string,
): Array<Object> {
  const arr: Array<Object> = [];

  for (const d of data) {
    arr.push({
      label: _.get(d, labelKey),
      value: _.get(d, valueKey),
    });
  }

  return arr;
}

export function handleError(error: any) {
  const errors = error?.response?.data?.errors || [];
  errors.forEach((error: { message: string; error_message: string }) => {
    toast.error(
      error?.error_message || error?.message || "Something went wrong, Please try again later!",
    );
  });
}
export function handleResponse(response: any) {
  const message = response.data?.message;
  if (message) {
    toast.success(message);
  }
}
