import React, { FC } from "react";

import Header from "components/layout_v2/Header";
import Footer from "components/layout_v2/Footer";
import { ContactType } from "models/graphql/contact";

import "assets/css/bootstrap.min.css";
import "assets/css/slick.css";
import "assets/css/animate.css";
import "assets/css/boxicons.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "assets/styles/style.css";
import "assets/css/responsive.css";

type PageContextKeysType = "menuItems" | "page" | "uid";

type Props = {
  contact?: ContactType;
  pageContext: any;
};

const Layout: FC<Props> = ({ children, pageContext, contact }:any) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header pageContext={pageContext} />
      <main className="d-block flex-grow-1">{children}</main>
      <Footer contact={contact} />
    </div>
  );
};

export default Layout;
