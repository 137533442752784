import React from "react";
import GoogleMapReact from "google-map-react";

const markerIcon = require("assets/images/new/marker-icon.png");

const CustomComponent = () => (
  <div>
    <img src={markerIcon} alt="marker" />
  </div>
);

const GoogleMapComponent = ({
  zoom = 11,
  center = {
    lat: 9.1,
    lng: 7.48,
  },
}) => {
  return (
    <div className="map-content">
      <GoogleMapReact
        defaultZoom={zoom}
        defaultCenter={center}
        bootstrapURLKeys={{
          key: process.env.GOOGLE_MAPS_API_KEY,
        }}
      >
        <CustomComponent lat={center.lat} lng={center.lng} text="Marker" />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMapComponent;
