import React from "react";

import Icon from "components/layout_v2/Icon";
import { PageSliceFeature } from "models/graphql/index";

const BoxAreas = ({ items }: PageSliceFeature) => {
  return (
    <section className="boxes-area">
      <div className="container">
        <div className="row">
          {items.map(({ icon, title, content }, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="single-box">
                {!!icon && (
                  <div className="icon">
                    <Icon name={icon} />
                  </div>
                )}

                {!!title.text && <h3>{title.text}</h3>}

                {!!content.text && <p>{content.text}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BoxAreas;
