import React, { FC } from 'react'
import { Link } from 'gatsby'
import { ArrowRight } from 'react-feather'

import { Pagination } from 'models/graphql/base'

interface Props {
  title?: string
  pageInfo: Pagination
  content?: { imageSrc: string; date: string; title: string; description: string; link: string }[]
}

const Blog: FC<Props> = ({ title, content = [], pageInfo }) => {
  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo

  return (
    <section className="blog-area ptb-80">
      <div className="container">
        {!!title && (
          <div className="section-title">
            <h2>{title}</h2>
          </div>
        )}

        <div className="row">
          {content.map(({ imageSrc, title, link, date, description }, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="single-blog-post">
                <div className="blog-image">
                  <Link to={link}>
                    <img src={imageSrc} alt={title} />
                  </Link>
                </div>

                <div className="blog-post-content">
                  <span>{date}</span>

                  <h3>
                    <Link to={link}>{title}</Link>
                  </h3>

                  <p>{description}</p>
                  <Link to={link} className="read-more-btn">
                    Read More <ArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          ))}

          <div className="col-lg-12 col-md-12">
            <div className="pagination-area">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  {hasPreviousPage && (
                    <li className="page-item">
                      <Link
                        to={`/resources/news-update/page/${currentPage - 1}`}
                        className="page-link"
                      >
                        Previous
                      </Link>
                    </li>
                  )}

                  {Array.from({ length: pageCount }).map((_, i) => {
                    const count = i + 1
                    const className = currentPage === count ? 'page-item active' : 'page-item'

                    return (
                      <li key={i} className={className}>
                        <Link to={`/resources/news-update/page/${count}`} className="page-link">
                          {count}
                        </Link>
                      </li>
                    )
                  })}

                  {hasNextPage && (
                    <li className="page-item">
                      <Link
                        to={`/resources/news-update/page/${currentPage + 1}`}
                        className="page-link"
                      >
                        Next
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
