import React from "react";

import { PageSlicePlain } from "models/graphql/index";

const Content = ({ primary }: PageSlicePlain) => (
  <section className="about-area ptb-80">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="about-content">
            <div className="section-title">
              <h2>{primary.title.text}</h2>
            </div>

            <div dangerouslySetInnerHTML={{ __html: primary.content.html }} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Content;
