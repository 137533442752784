import { authClass } from './config';
import { AUTH_CLASS_TYPE } from "./../models/types/Services";

export const AUTHENTICATION_CLASS: AUTH_CLASS_TYPE = authClass;

export enum Intents {
  retrieve = "retrieve",
  retrieveOne = "retrieveOne",
  create = "create",
  createBulk = "createBulk",
  update = "update",
  updateMany = "updateMany",
  patch = "patch",
  delete = "delete",
  deleteOne = "deleteOne",
  authenticate = "authenticate",
  logout = "logout",
  verifyToken = "verifyToken",
  refreshToken = "refreshToken",
  metadata = "metadata",
  information = "information",
}

export enum Schemas {
  model = "model",
  controller = "controller",
}

export const DEFAULT_PAGE_LIMIT = 10;

export const HANDSONTABLE_LICENSE = "non-commercial-and-evaluation";

export enum UserTypes {
  corporate_representative = "CORPORATE_REPRESENTATIVE",
  mda_user = "MDA_USER",
  mda_admin = "MDA_ADMIN",
  admin = "ADMIN",
  super_admin = "SUPER_ADMIN",
}

export enum UserCategories {
  admin = "admin",
  mda = "mda",
  reviewer = "reviewer",
  others = "others",
}

export const AdminUsers = ["ADMIN", "SUPER_ADMIN"];
export const MdaUsers = ["MDA_USER", "MDA_ADMIN"];
export const MdaAdmin = ["MDA_ADMIN"]
