import React, { FC } from 'react'
import * as FEIcon from 'react-feather'

interface Props {
  name: string
  size?: number
}

const Icon: FC<Props> = ({ name, size = 24 }) => {
  switch (name) {
    case 'Airplay':
      return <FEIcon.Airplay size={size} />

    case 'AlertCircle':
      return <FEIcon.AlertCircle size={size} />

    case 'AlertOctagon':
      return <FEIcon.AlertOctagon size={size} />

    case 'AlertTriangle':
      return <FEIcon.AlertTriangle size={size} />

    case 'AlignCenter':
      return <FEIcon.AlignCenter size={size} />

    case 'AlignJustify':
      return <FEIcon.AlignJustify size={size} />

    case 'AlignLeft':
      return <FEIcon.AlignLeft size={size} />

    case 'AlignRight':
      return <FEIcon.AlignRight size={size} />

    case 'Anchor':
      return <FEIcon.Anchor size={size} />

    case 'Aperture':
      return <FEIcon.Aperture size={size} />

    case 'Archive':
      return <FEIcon.Archive size={size} />

    case 'ArrowDownCircle':
      return <FEIcon.ArrowDownCircle size={size} />

    case 'ArrowDownLeft':
      return <FEIcon.ArrowDownLeft size={size} />

    case 'ArrowDownRight':
      return <FEIcon.ArrowDownRight size={size} />

    case 'ArrowDown':
      return <FEIcon.ArrowDown size={size} />

    case 'ArrowLeftCircle':
      return <FEIcon.ArrowLeftCircle size={size} />

    case 'ArrowLeft':
      return <FEIcon.ArrowLeft size={size} />

    case 'ArrowRightCircle':
      return <FEIcon.ArrowRightCircle size={size} />

    case 'ArrowRight':
      return <FEIcon.ArrowRight size={size} />

    case 'ArrowUpCircle':
      return <FEIcon.ArrowUpCircle size={size} />

    case 'ArrowUpLeft':
      return <FEIcon.ArrowUpLeft size={size} />

    case 'ArrowUpRight':
      return <FEIcon.ArrowUpRight size={size} />

    case 'ArrowUp':
      return <FEIcon.ArrowUp size={size} />

    case 'AtSign':
      return <FEIcon.AtSign size={size} />

    case 'Award':
      return <FEIcon.Award size={size} />

    case 'BarChart2':
      return <FEIcon.BarChart2 size={size} />

    case 'BarChart':
      return <FEIcon.BarChart size={size} />

    case 'BatteryCharging':
      return <FEIcon.BatteryCharging size={size} />

    case 'Battery':
      return <FEIcon.Battery size={size} />

    case 'BellOff':
      return <FEIcon.BellOff size={size} />

    case 'Bell':
      return <FEIcon.Bell size={size} />

    case 'Bluetooth':
      return <FEIcon.Bluetooth size={size} />

    case 'Bold':
      return <FEIcon.Bold size={size} />

    case 'BookOpen':
      return <FEIcon.BookOpen size={size} />

    case 'Book':
      return <FEIcon.Book size={size} />

    case 'Bookmark':
      return <FEIcon.Bookmark size={size} />

    case 'Box':
      return <FEIcon.Box size={size} />

    case 'Briefcase':
      return <FEIcon.Briefcase size={size} />

    case 'Calendar':
      return <FEIcon.Calendar size={size} />

    case 'CameraOff':
      return <FEIcon.CameraOff size={size} />

    case 'Camera':
      return <FEIcon.Camera size={size} />

    case 'Cast':
      return <FEIcon.Cast size={size} />

    case 'CheckCircle':
      return <FEIcon.CheckCircle size={size} />

    case 'CheckSquare':
      return <FEIcon.CheckSquare size={size} />

    case 'Check':
      return <FEIcon.Check size={size} />

    case 'ChevronDown':
      return <FEIcon.ChevronDown size={size} />

    case 'ChevronLeft':
      return <FEIcon.ChevronLeft size={size} />

    case 'ChevronRight':
      return <FEIcon.ChevronRight size={size} />

    case 'ChevronUp':
      return <FEIcon.ChevronUp size={size} />

    case 'ChevronsDown':
      return <FEIcon.ChevronsDown size={size} />

    case 'ChevronsLeft':
      return <FEIcon.ChevronsLeft size={size} />

    case 'ChevronsRight':
      return <FEIcon.ChevronsRight size={size} />

    case 'ChevronsUp':
      return <FEIcon.ChevronsUp size={size} />

    case 'Chrome':
      return <FEIcon.Chrome size={size} />

    case 'Circle':
      return <FEIcon.Circle size={size} />

    case 'Clipboard':
      return <FEIcon.Clipboard size={size} />

    case 'Clock':
      return <FEIcon.Clock size={size} />

    case 'CloudDrizzle':
      return <FEIcon.CloudDrizzle size={size} />

    case 'CloudLightning':
      return <FEIcon.CloudLightning size={size} />

    case 'CloudOff':
      return <FEIcon.CloudOff size={size} />

    case 'CloudRain':
      return <FEIcon.CloudRain size={size} />

    case 'CloudSnow':
      return <FEIcon.CloudSnow size={size} />

    case 'Cloud':
      return <FEIcon.Cloud size={size} />

    case 'Code':
      return <FEIcon.Code size={size} />

    case 'Codepen':
      return <FEIcon.Codepen size={size} />

    case 'Codesandbox':
      return <FEIcon.Codesandbox size={size} />

    case 'Coffee':
      return <FEIcon.Coffee size={size} />

    case 'Columns':
      return <FEIcon.Columns size={size} />

    case 'Command':
      return <FEIcon.Command size={size} />

    case 'Compass':
      return <FEIcon.Compass size={size} />

    case 'Copy':
      return <FEIcon.Copy size={size} />

    case 'CornerDownLeft':
      return <FEIcon.CornerDownLeft size={size} />

    case 'CornerDownRight':
      return <FEIcon.CornerDownRight size={size} />

    case 'CornerLeftDown':
      return <FEIcon.CornerLeftDown size={size} />

    case 'CornerLeftUp':
      return <FEIcon.CornerLeftUp size={size} />

    case 'CornerRightDown':
      return <FEIcon.CornerRightDown size={size} />

    case 'CornerRightUp':
      return <FEIcon.CornerRightUp size={size} />

    case 'CornerUpLeft':
      return <FEIcon.CornerUpLeft size={size} />

    case 'CornerUpRight':
      return <FEIcon.CornerUpRight size={size} />

    case 'Cpu':
      return <FEIcon.Cpu size={size} />

    case 'CreditCard':
      return <FEIcon.CreditCard size={size} />

    case 'Crop':
      return <FEIcon.Crop size={size} />

    case 'Crosshair':
      return <FEIcon.Crosshair size={size} />

    case 'Database':
      return <FEIcon.Database size={size} />

    case 'Delete':
      return <FEIcon.Delete size={size} />

    case 'Disc':
      return <FEIcon.Disc size={size} />

    case 'DivideCircle':
      return <FEIcon.DivideCircle size={size} />

    case 'DivideSquare':
      return <FEIcon.DivideSquare size={size} />

    case 'Divide':
      return <FEIcon.Divide size={size} />

    case 'DollarSign':
      return <FEIcon.DollarSign size={size} />

    case 'DownloadCloud':
      return <FEIcon.DownloadCloud size={size} />

    case 'Download':
      return <FEIcon.Download size={size} />

    case 'Dribbble':
      return <FEIcon.Dribbble size={size} />

    case 'Droplet':
      return <FEIcon.Droplet size={size} />

    case 'Edit2':
      return <FEIcon.Edit2 size={size} />

    case 'Edit3':
      return <FEIcon.Edit3 size={size} />

    case 'Edit':
      return <FEIcon.Edit size={size} />

    case 'ExternalLink':
      return <FEIcon.ExternalLink size={size} />

    case 'EyeOff':
      return <FEIcon.EyeOff size={size} />

    case 'Eye':
      return <FEIcon.Eye size={size} />

    case 'Facebook':
      return <FEIcon.Facebook size={size} />

    case 'FastForward':
      return <FEIcon.FastForward size={size} />

    case 'Feather':
      return <FEIcon.Feather size={size} />

    case 'Figma':
      return <FEIcon.Figma size={size} />

    case 'FileMinus':
      return <FEIcon.FileMinus size={size} />

    case 'FilePlus':
      return <FEIcon.FilePlus size={size} />

    case 'FileText':
      return <FEIcon.FileText size={size} />

    case 'File':
      return <FEIcon.File size={size} />

    case 'Film':
      return <FEIcon.Film size={size} />

    case 'Filter':
      return <FEIcon.Filter size={size} />

    case 'Flag':
      return <FEIcon.Flag size={size} />

    case 'FolderMinus':
      return <FEIcon.FolderMinus size={size} />

    case 'FolderPlus':
      return <FEIcon.FolderPlus size={size} />

    case 'Folder':
      return <FEIcon.Folder size={size} />

    case 'Framer':
      return <FEIcon.Framer size={size} />

    case 'Frown':
      return <FEIcon.Frown size={size} />

    case 'Gift':
      return <FEIcon.Gift size={size} />

    case 'GitBranch':
      return <FEIcon.GitBranch size={size} />

    case 'GitCommit':
      return <FEIcon.GitCommit size={size} />

    case 'GitMerge':
      return <FEIcon.GitMerge size={size} />

    case 'GitPullRequest':
      return <FEIcon.GitPullRequest size={size} />

    case 'GitHub':
      return <FEIcon.GitHub size={size} />

    case 'Gitlab':
      return <FEIcon.Gitlab size={size} />

    case 'Globe':
      return <FEIcon.Globe size={size} />

    case 'Grid':
      return <FEIcon.Grid size={size} />

    case 'HardDrive':
      return <FEIcon.HardDrive size={size} />

    case 'Hash':
      return <FEIcon.Hash size={size} />

    case 'Headphones':
      return <FEIcon.Headphones size={size} />

    case 'Heart':
      return <FEIcon.Heart size={size} />

    case 'HelpCircle':
      return <FEIcon.HelpCircle size={size} />

    case 'Hexagon':
      return <FEIcon.Hexagon size={size} />

    case 'Home':
      return <FEIcon.Home size={size} />

    case 'Image':
      return <FEIcon.Image size={size} />

    case 'Inbox':
      return <FEIcon.Inbox size={size} />

    case 'Info':
      return <FEIcon.Info size={size} />

    case 'Instagram':
      return <FEIcon.Instagram size={size} />

    case 'Italic':
      return <FEIcon.Italic size={size} />

    case 'Key':
      return <FEIcon.Key size={size} />

    case 'Layers':
      return <FEIcon.Layers size={size} />

    case 'Layout':
      return <FEIcon.Layout size={size} />

    case 'LifeBuoy':
      return <FEIcon.LifeBuoy size={size} />

    case 'Link2':
      return <FEIcon.Link2 size={size} />

    case 'Link':
      return <FEIcon.Link size={size} />

    case 'Linkedin':
      return <FEIcon.Linkedin size={size} />

    case 'List':
      return <FEIcon.List size={size} />

    case 'Loader':
      return <FEIcon.Loader size={size} />

    case 'Lock':
      return <FEIcon.Lock size={size} />

    case 'LogIn':
      return <FEIcon.LogIn size={size} />

    case 'LogOut':
      return <FEIcon.LogOut size={size} />

    case 'Mail':
      return <FEIcon.Mail size={size} />

    case 'MapPin':
      return <FEIcon.MapPin size={size} />

    case 'Map':
      return <FEIcon.Map size={size} />

    case 'Maximize2':
      return <FEIcon.Maximize2 size={size} />

    case 'Maximize':
      return <FEIcon.Maximize size={size} />

    case 'Meh':
      return <FEIcon.Meh size={size} />

    case 'Menu':
      return <FEIcon.Menu size={size} />

    case 'MessageCircle':
      return <FEIcon.MessageCircle size={size} />

    case 'MessageSquare':
      return <FEIcon.MessageSquare size={size} />

    case 'MicOff':
      return <FEIcon.MicOff size={size} />

    case 'Mic':
      return <FEIcon.Mic size={size} />

    case 'Minimize2':
      return <FEIcon.Minimize2 size={size} />

    case 'Minimize':
      return <FEIcon.Minimize size={size} />

    case 'MinusCircle':
      return <FEIcon.MinusCircle size={size} />

    case 'MinusSquare':
      return <FEIcon.MinusSquare size={size} />

    case 'Minus':
      return <FEIcon.Minus size={size} />

    case 'Monitor':
      return <FEIcon.Monitor size={size} />

    case 'Moon':
      return <FEIcon.Moon size={size} />

    case 'MoreHorizontal':
      return <FEIcon.MoreHorizontal size={size} />

    case 'MoreVertical':
      return <FEIcon.MoreVertical size={size} />

    case 'MousePointer':
      return <FEIcon.MousePointer size={size} />

    case 'Move':
      return <FEIcon.Move size={size} />

    case 'Music':
      return <FEIcon.Music size={size} />

    case 'Navigation2':
      return <FEIcon.Navigation2 size={size} />

    case 'Navigation':
      return <FEIcon.Navigation size={size} />

    case 'Octagon':
      return <FEIcon.Octagon size={size} />

    case 'Package':
      return <FEIcon.Package size={size} />

    case 'Paperclip':
      return <FEIcon.Paperclip size={size} />

    case 'PauseCircle':
      return <FEIcon.PauseCircle size={size} />

    case 'Pause':
      return <FEIcon.Pause size={size} />

    case 'PenTool':
      return <FEIcon.PenTool size={size} />

    case 'Percent':
      return <FEIcon.Percent size={size} />

    case 'PhoneCall':
      return <FEIcon.PhoneCall size={size} />

    case 'PhoneForwarded':
      return <FEIcon.PhoneForwarded size={size} />

    case 'PhoneIncoming':
      return <FEIcon.PhoneIncoming size={size} />

    case 'PhoneMissed':
      return <FEIcon.PhoneMissed size={size} />

    case 'PhoneOff':
      return <FEIcon.PhoneOff size={size} />

    case 'PhoneOutgoing':
      return <FEIcon.PhoneOutgoing size={size} />

    case 'Phone':
      return <FEIcon.Phone size={size} />

    case 'PieChart':
      return <FEIcon.PieChart size={size} />

    case 'PlayCircle':
      return <FEIcon.PlayCircle size={size} />

    case 'Play':
      return <FEIcon.Play size={size} />

    case 'PlusCircle':
      return <FEIcon.PlusCircle size={size} />

    case 'PlusSquare':
      return <FEIcon.PlusSquare size={size} />

    case 'Plus':
      return <FEIcon.Plus size={size} />

    case 'Pocket':
      return <FEIcon.Pocket size={size} />

    case 'Power':
      return <FEIcon.Power size={size} />

    case 'Printer':
      return <FEIcon.Printer size={size} />

    case 'Radio':
      return <FEIcon.Radio size={size} />

    case 'RefreshCcw':
      return <FEIcon.RefreshCcw size={size} />

    case 'RefreshCw':
      return <FEIcon.RefreshCw size={size} />

    case 'Repeat':
      return <FEIcon.Repeat size={size} />

    case 'Rewind':
      return <FEIcon.Rewind size={size} />

    case 'RotateCcw':
      return <FEIcon.RotateCcw size={size} />

    case 'RotateCw':
      return <FEIcon.RotateCw size={size} />

    case 'Rss':
      return <FEIcon.Rss size={size} />

    case 'Save':
      return <FEIcon.Save size={size} />

    case 'Scissors':
      return <FEIcon.Scissors size={size} />

    case 'Search':
      return <FEIcon.Search size={size} />

    case 'Send':
      return <FEIcon.Send size={size} />

    case 'Server':
      return <FEIcon.Server size={size} />

    case 'Settings':
      return <FEIcon.Settings size={size} />

    case 'Share2':
      return <FEIcon.Share2 size={size} />

    case 'Share':
      return <FEIcon.Share size={size} />

    case 'ShieldOff':
      return <FEIcon.ShieldOff size={size} />

    case 'Shield':
      return <FEIcon.Shield size={size} />

    case 'ShoppingBag':
      return <FEIcon.ShoppingBag size={size} />

    case 'ShoppingCart':
      return <FEIcon.ShoppingCart size={size} />

    case 'Shuffle':
      return <FEIcon.Shuffle size={size} />

    case 'Sidebar':
      return <FEIcon.Sidebar size={size} />

    case 'SkipBack':
      return <FEIcon.SkipBack size={size} />

    case 'SkipForward':
      return <FEIcon.SkipForward size={size} />

    case 'Slack':
      return <FEIcon.Slack size={size} />

    case 'Slash':
      return <FEIcon.Slash size={size} />

    case 'Sliders':
      return <FEIcon.Sliders size={size} />

    case 'Smartphone':
      return <FEIcon.Smartphone size={size} />

    case 'Smile':
      return <FEIcon.Smile size={size} />

    case 'Speaker':
      return <FEIcon.Speaker size={size} />

    case 'Square':
      return <FEIcon.Square size={size} />

    case 'Star':
      return <FEIcon.Star size={size} />

    case 'StopCircle':
      return <FEIcon.StopCircle size={size} />

    case 'Sun':
      return <FEIcon.Sun size={size} />

    case 'Sunrise':
      return <FEIcon.Sunrise size={size} />

    case 'Sunset':
      return <FEIcon.Sunset size={size} />

    case 'Tablet':
      return <FEIcon.Tablet size={size} />

    case 'Tag':
      return <FEIcon.Tag size={size} />

    case 'Target':
      return <FEIcon.Target size={size} />

    case 'Terminal':
      return <FEIcon.Terminal size={size} />

    case 'Thermometer':
      return <FEIcon.Thermometer size={size} />

    case 'ThumbsDown':
      return <FEIcon.ThumbsDown size={size} />

    case 'ThumbsUp':
      return <FEIcon.ThumbsUp size={size} />

    case 'ToggleLeft':
      return <FEIcon.ToggleLeft size={size} />

    case 'ToggleRight':
      return <FEIcon.ToggleRight size={size} />

    case 'Tool':
      return <FEIcon.Tool size={size} />

    case 'Trash2':
      return <FEIcon.Trash2 size={size} />

    case 'Trash':
      return <FEIcon.Trash size={size} />

    case 'Trello':
      return <FEIcon.Trello size={size} />

    case 'TrendingDown':
      return <FEIcon.TrendingDown size={size} />

    case 'TrendingUp':
      return <FEIcon.TrendingUp size={size} />

    case 'Triangle':
      return <FEIcon.Triangle size={size} />

    case 'Truck':
      return <FEIcon.Truck size={size} />

    case 'Tv':
      return <FEIcon.Tv size={size} />

    case 'Twitch':
      return <FEIcon.Twitch size={size} />

    case 'Twitter':
      return <FEIcon.Twitter size={size} />

    case 'Type':
      return <FEIcon.Type size={size} />

    case 'Umbrella':
      return <FEIcon.Umbrella size={size} />

    case 'Underline':
      return <FEIcon.Underline size={size} />

    case 'Unlock':
      return <FEIcon.Unlock size={size} />

    case 'UploadCloud':
      return <FEIcon.UploadCloud size={size} />

    case 'Upload':
      return <FEIcon.Upload size={size} />

    case 'UserCheck':
      return <FEIcon.UserCheck size={size} />

    case 'UserMinus':
      return <FEIcon.UserMinus size={size} />

    case 'UserPlus':
      return <FEIcon.UserPlus size={size} />

    case 'UserX':
      return <FEIcon.UserX size={size} />

    case 'User':
      return <FEIcon.User size={size} />

    case 'Users':
      return <FEIcon.Users size={size} />

    case 'VideoOff':
      return <FEIcon.VideoOff size={size} />

    case 'Video':
      return <FEIcon.Video size={size} />

    case 'Voicemail':
      return <FEIcon.Voicemail size={size} />

    case 'Volume1':
      return <FEIcon.Volume1 size={size} />

    case 'Volume2':
      return <FEIcon.Volume2 size={size} />

    case 'VolumeX':
      return <FEIcon.VolumeX size={size} />

    case 'Volume':
      return <FEIcon.Volume size={size} />

    case 'Watch':
      return <FEIcon.Watch size={size} />

    case 'WifiOff':
      return <FEIcon.WifiOff size={size} />

    case 'Wifi':
      return <FEIcon.Wifi size={size} />

    case 'Wind':
      return <FEIcon.Wind size={size} />

    case 'XCircle':
      return <FEIcon.XCircle size={size} />

    case 'XOctagon':
      return <FEIcon.XOctagon size={size} />

    case 'XSquare':
      return <FEIcon.XSquare size={size} />

    case 'X':
      return <FEIcon.X size={size} />

    case 'Youtube':
      return <FEIcon.Youtube size={size} />

    case 'ZapOff':
      return <FEIcon.ZapOff size={size} />

    case 'Zap':
      return <FEIcon.Zap size={size} />

    case 'ZoomIn':
      return <FEIcon.ZoomIn size={size} />

    case 'ZoomOut':
      return <FEIcon.ZoomOut size={size} />

    default:
      return <FEIcon.Activity size={size} />
  }
}

export default Icon
