import React, { FC, useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { ChevronDown } from "react-feather";
import { AuthContext } from "contexts/index";
import { NavigationWrapperType } from "models/graphql/index";
import { useLocation } from "@reach/router";
import { UserTypes } from "models/db-schema";
import { AdminUsers, MdaUsers } from "common/constants";
import AuthenticationStore from "stores/AuthenticationStore";

const logo = require("assets/images/new/logo.png");

const ADMIN_URLS = [
  {
    path: "/app/admin/mdas",
    label: "MDAs",
  },
  {
    path: "/app/admin/debts",
    label: "Debts",
  },
  {
    path: "/app/admin/payments",
    label: "Payments",
  },
];

const SUPER_ADMIN_URLS = [
  {
    path: "/app/admin/users",
    label: "Users",
  },
  {
    path: "/app/admin/companies",
    label: "Companies",
  },
  {
    path: "/app/admin/mdas",
    label: "MDAs",
  },
  {
    path: "/app/admin/debts",
    label: "Debts",
  },
  {
    path: "/app/admin/payments",
    label: "Payments",
  },
];

const MDA_URLS = [
  {
    path: "/app/admin/companies",
    label: "Companies",
  },
  {
    path: "/app/admin/debts",
    label: "Debts",
  },
  {
    path: "/app/admin/payments",
    label: "Payments",
  },
];
const MDA_ADMIN_URLS = [
  ...MDA_URLS,
  {
    path: "/app/admin/users",
    label: "Users",
  },
];
const getNavLinks = (userType: string) => {
  switch (userType) {
    case UserTypes.SUPER_ADMIN:
      return SUPER_ADMIN_URLS;
    case UserTypes.ADMIN:
      return ADMIN_URLS;
    case UserTypes.MDA_USER:
      return MDA_URLS;
    case UserTypes.MDA_ADMIN:
      return MDA_ADMIN_URLS;
    default:
      return [];
  }
};

const APP_URLS = [];

const Header: FC<NavigationWrapperType> = ({ pageContext = {} }) => {
  const { menuItems = [] } = pageContext;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { isLoggedIn, currentUser } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isAdmin = pathname.indexOf("/app/admin") > -1;
  const isApp = !isAdmin && pathname.indexOf("/app") > -1;

  const toggleNavbar = () => {
    setCollapsed((val) => !val);
  };

  const navToLogin = () => {
    navigate("/app/auth/login");
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");

    const manageScrollClass = () => {
      if (window.scrollY > 170) {
        elementId?.classList.add("is-sticky");
      } else {
        elementId?.classList.remove("is-sticky");
      }
    };

    document.addEventListener("scroll", manageScrollClass);
    return () => document.removeEventListener("scroll", manageScrollClass);
  }, []);

  const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";
  const links = getNavLinks(currentUser?.userType || "");
  return (
    <header id="header">
      <div id="navbar" className="startp-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link to="/" className="navbar-brand">
              <img alt="logo" src={logo} />
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="#156148"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="navbar-toggler-icon nav-icon-open"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 12h18M3 6h18M3 18h18" />
              </svg>
              <svg
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="#156148"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="navbar-toggler-icon nav-icon-close"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 6L6 18M6 6l12 12" />
              </svg>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav nav ml-auto">
                {!isLoggedIn &&
                  menuItems
                    .filter(
                      (menuItem) => menuItem.primary.published && menuItem.primary.show_in_menu,
                    )
                    .map(({ items, primary }, index) => {
                      const path = primary.path
                        ? `/${primary.path}`
                        : primary.is_dropdown && !primary.path
                        ? `/#${primary.label.toLowerCase()}`
                        : "/";

                      return (
                        <li key={`key__menu__${primary.page}__${index}`} className="nav-item">
                          <Link className="nav-link" activeClassName="active" to={path}>
                            {primary.label} {primary.is_dropdown && <ChevronDown />}
                          </Link>

                          {!!items.length && (
                            <ul className="dropdown_menu">
                              {items.map((submenu, i) => (
                                <li className="nav-item" key={`key__subMenu__${i}`}>
                                  <Link
                                    className="nav-link"
                                    activeClassName="active"
                                    to={`/${submenu.path}`}
                                  >
                                    {submenu.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    })}

                {!isLoggedIn && (
                  <li className="nav-item">
                    <button className="login-btn" type="button" onClick={navToLogin}>
                      Pay Your Debt
                    </button>
                  </li>
                )}

                {/* Authenticated Page */}
                {isLoggedIn && (
                  <li className="nav-item">
                    <Link className="nav-link" activeClassName="active" to={"/app/welcome"}>
                      Dashboard
                    </Link>
                  </li>
                )}

                {isLoggedIn &&
                  isApp &&
                  !["MDA_USER", "MDA_ADMIN", "ADMIN"].includes(currentUser?.userType || "") && (
                    <li className="nav-item">
                      <Link className="nav-link" activeClassName="active" to={"/app/search"}>
                        Associate a Company
                      </Link>
                    </li>
                  )}

                {isLoggedIn &&
                  isApp &&
                  ["SUPER_ADMIN", "ADMIN", "MDA_ADMIN", "MDA_USER"].includes(
                    currentUser?.userType || "",
                  ) && (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to={"/app/admin/companies"}
                      >
                        Admin Section
                      </Link>
                    </li>
                  )}

                {/* Admin Page */}
                {isLoggedIn &&
                  isAdmin &&
                  links.map((v, index) => {
                    return (
                      <li key={`key__menu__${v.label}__${index}`} className="nav-item">
                        <Link className="nav-link" activeClassName="active" to={v.path}>
                          {v.label}
                        </Link>
                      </li>
                    );
                  })}

                {/* Corporate Authenticated Page  */}

                {/* Authenticated Page */}
                {isLoggedIn && (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/app/profile-settings"
                      >
                        My profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <button
                        className="login-btn"
                        type="button"
                        onClick={() => AuthenticationStore.logout()}
                      >
                        Logout
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
