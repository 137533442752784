import React, { FC } from "react";

import Icon from "components/layout_v2/Icon";
import { PageSliceCategory } from "models/graphql/index";

const BoxAreas2: FC<PageSliceCategory> = ({ primary, items, children }) => {
  return (
    <section className="about-area ptb-80 bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-content">
              {!!primary.title.text && (
                <div className="section-title">
                  <h2>{primary.title.text}</h2>
                </div>
              )}

              {children}

              <div className="row">
                {items.map(({ icon, title, content }, i) => (
                  <div key={i} className="col-lg-6 col-md-6">
                    <div className="single-features">
                      <div className="icon">
                        <Icon name={icon} />
                      </div>

                      <h3>{title.text}</h3>
                      <p>{content.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxAreas2;
