import React from 'react'
import OwlCarousel from 'react-owl-carousel3'

import { GlobalPageType } from 'models/graphql/page'

const slideOptions = {
  loop: true,
  nav: false,
  autoplay: true,
  dots: false,
  center: true,
  autoWidth: true,
}

const Stakeholders = ({ data }: GlobalPageType) => {
  const _data = data?.allPrismicStakeholders.edges[0]?.node.data

  if (!_data || typeof window === 'undefined') return null

  const { title, items } = _data
  const logos: { url: string; alt: string }[] = items.map(({ image: { url, alt } }) => {
    return { url, alt }
  })

  return (
    <section className="works-area ptb-80 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          <h2>{title.text}</h2>
        </div>
      </div>

      <div className="row m-0">
        <OwlCarousel className="works-slides owl-carousel owl-theme" {...slideOptions}>
          {logos.map(({ url, alt }: any, i) => (
            <div key={i} className="item">
              <div className="single-works">
                <img alt={alt} src={url} />
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  )
}

export default Stakeholders
