import React from "react";

import Icon from "components/layout_v2/Icon";
import { BaseQueryType } from "models/graphql/base";
import { ReportsType } from "models/graphql/reports";

interface Content {
  icon?: string;
  link: string;
  title: string;
  description: string;
}

const DownloadCards = ({ data }: BaseQueryType<ReportsType>) => {
  const _data = data?.allPrismicPage.edges[0].node.data.body;
  const [_, components] = _data;

  const handleClick = (link: string) => {
    if (window) {
      window.open(link, "_blank");
    }
  };

  const content: Content[] = components.items.map(({ key: title, link, description }) => {
    return { title, link: link.url, description: description.text };
  });
  return (
    <section className="services-area-two ptb-80 bg-f9f6f6">
      <div className="container">
        <div className="row">
          {content.map(({ icon = "Download", link, title, description }, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="single-services-box cursor-pointer" onClick={() => handleClick(link)}>
                <div className="icon">
                  <Icon name={icon} />
                </div>

                {!!title && <h3>{title}</h3>}

                {!!description && <p>{description}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DownloadCards;
