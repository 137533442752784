import React from "react";
import { Link } from "gatsby";

import { PageSliceSlidingHero } from "models/graphql/index";

const MainBanner = ({ primary }: PageSliceSlidingHero) => {
  const { title, sub_title, button_label, button_link, show_button, image } = primary;
  // { title, description, btnText, btnLink, bannerImage }
  return (
    <div className="main-banner">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="hero-content">
                  {!!title.text && <h1>{title.text}</h1>}

                  {!!sub_title.text && <p>{sub_title.text}</p>}

                  {!!show_button && (
                    <Link to={button_link} className="btn btn-primary">
                      {button_label}
                    </Link>
                  )}
                </div>
              </div>

              <div className="col-lg-6 offset-lg-1">
                {!!image.url && <img src={image.url} alt="main-pic" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
