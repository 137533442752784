import React from "react";
import { Link } from "gatsby";

import { PageSliceActionSection } from "models/graphql/index";

const ContentWithCTA = ({ primary }: PageSliceActionSection) => {
  const {
    title,
    content,
    display_button,
    button_label,
    button_action_path,
    supporting_text,
  } = primary;

  return (
    <section className="funfacts-area ptb-80">
      <div className="container">
        {(title.text || content.text) && (
          <div className="section-title">
            {!!title.text && (
              <>
                <h2>{title.text}</h2>
                <div className="bar"></div>
              </>
            )}

            {!!content.text && <p>{content.text}</p>}
          </div>
        )}

        {(supporting_text || display_button) && (
          <div className="contact-cta-box">
            {!!supporting_text && <p>{supporting_text}</p>}

            {!!display_button && (
              <Link to={button_action_path} className="btn btn-primary">
                {button_label}
              </Link>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentWithCTA;
